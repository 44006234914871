
import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import * as JSTool from '../store/JSTool';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getImgPath } from '../store/JSTool';
import TitleView from '../component/TitleView';
import * as rdd from 'react-device-detect';

export default function Page1(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    let isStart = false;
    let isFinish = false;
    let [showTime, setShowTime] = React.useState(false);
    let deadLine = '2024-09-05 00:00:00'; //截止时间

    const [hourStr, setHourStr] = React.useState('00');
    const [minuteStr, setMinuteStr] = React.useState('00');
    const [secondStr, setSecondStr] = React.useState('00');

    React.useEffect(() => {
        start();
    }, []);

    function subscribeTap() {
        if(!settingData.isMobile){
            dispatch(settingActions.showRegisterAlert(true));
            JSTool.trackEvent('show_email_pop');
        }else{
            if(JSTool.isIOS){
                JSTool.appleTap();
            }else{
                JSTool.googleTap();
            }
        }
    }

    function start() {
        if (isStart) {
            return;
        }

        isStart = true;
        //格式化时间
        const formatTime = (time) => {
            if (time < 10) time = '0' + time
            return time
        }


        function getTotalSeconds() {
            const big = getBjDate(new Date(deadLine));
            const small = getSmallDate(new Date());
            const totalSeconds = Math.ceil((big - small) / (1000));
            if (totalSeconds <= 0) {
                isFinish = true;
            }
            return totalSeconds;
        }

        function getSmallDate(date) {
            // 本地时间距离（GMT时间）毫秒数
            let nowDate = !date ? new Date().getTime() : new Date(date).getTime()
            // 本地时间和格林威治时间差，单位分钟
            let offset_GMT = new Date().getTimezoneOffset() //东八区是 -(8*60)
            //  反推到格林尼治时间
            let GMT = nowDate + offset_GMT * 60 * 1000
            //  获取指定时区时间
            let targetDate = new Date(GMT + (8 * 60 * 60 * 1000) + (480 + offset_GMT) * 60 * 1000)  //先推算到北京时间，再加上北京时间和它的差值
            return targetDate
        }

        function getBjDate(date) {
            // 本地时间距离（GMT时间）毫秒数
            let nowDate = !date ? new Date().getTime() : new Date(date).getTime()
            // 本地时间和格林威治时间差，单位分钟
            let offset_GMT = new Date().getTimezoneOffset()
            //  反推到格林尼治时间
            let GMT = nowDate + offset_GMT * 60 * 1000
            //  获取指定时区时间
            let targetDate = new Date(GMT + 8 * 60 * 60 * 1000)
            return targetDate
        }

        const changeTime = () => {
            if (isFinish) {
                setShowTime(false);
                return;
            }

            setShowTime(true);
            let totalSeconds = getTotalSeconds(); //new Date();
            let hour = Math.floor(totalSeconds / 3600);
            let minute = Math.floor(totalSeconds / 60 % 60);
            let second = totalSeconds % 60;

            setHourStr(formatTime(hour));
            setMinuteStr(formatTime(minute));
            setSecondStr(formatTime(second));
            setTimeout(changeTime, 1000);
        }
        changeTime();
    }

    return (
        <Stack
            id={'page1'}
            sx={{
                position: 'relative',
                width: 1,
                overflow: 'hidden',
                backgroundSize: settingData.isMobile ? 'cover' : '100% 100%',
                backgroundImage: `url(${getImgPath('bg1_9.png')})`,
                alignItems: 'center',
            }}>

            {/* 顶部渐变色 */}
            <Box sx={{
                width: 1,
                height: '200px',
                background: 'linear-gradient(#000000cc , #00000000)',
                position: 'absolute',
                top: 0,
            }} />

            <Box
                component={'img'}
                src={getImgPath('pre_bg.png')}
                sx={{
                    position: 'absolute',
                    width: settingData.isMobile ? '110%' : '1300rem',
                    mt: settingData.isMobile ? (rdd.isIPad13 ? 22 : 15) : 23,
                }} />

            <Stack sx={{
                alignItems: 'center',
                zIndex: 1,
            }}>

                <TitleView title={'PRE-REGISTER NOW'} anchorId={props.anchorId} mt={settingData.isMobile ? 3 : 4} />

                <Typography sx={{
                    width: settingData.isMobile ? '350rem' : '350rem',
                    height: settingData.isMobile ? '150rem' : '160rem',
                    lineHeight: settingData.isMobile ? '140rem' : '150rem',
                    backgroundSize: '100% 100%',
                    color: 'white',
                    fontSize: settingData.isMobile ? '53rem' : '45rem',
                    textAlign: 'center',
                    backgroundImage: `url(${getImgPath('count_bg.png')})`,
                    fontWeight: 'bold',
                    mt: settingData.isMobile ? 10 : 20,
                    pt: 0.5,
                    visibility: showTime ? 'visiable': 'hidden'
                }}>
                    {hourStr + ':' + minuteStr + ':' + secondStr}
                </Typography>

                {/* 奖品 */}
                <Stack sx={{
                    alignItems: 'center',
                    width: settingData.isMobile ? '700rem' : '800rem',
                    height: settingData.isMobile ? '550rem' : '520rem',
                    backgroundSize: '100% 100%',
                    backgroundImage: `url(${getImgPath('money_bg.png')})`,
                    mb: props.isDownload && 4,
                }}>
                    <Box
                        component={'img'}
                        src={getImgPath('money_up.png')}
                        sx={{
                            mt: settingData.isMobile ? (rdd.isIPad13 ? 8 : 3.5) : 6,
                            height: '30rem'
                        }} />

                    <Typography sx={{
                        width: 1,
                        height: '40rem',
                        lineHeight: '40rem',
                        textAlign: 'center',
                        fontSize: settingData.isMobile ? '27rem' : '30rem',
                        color: 'black',
                        fontFamily: window.MyFont,
                    }}>
                        PRE-REGISTRATION MILESTONES
                    </Typography>

                    <Box
                        component={'img'}
                        src={getImgPath('money_bottom.png')}
                        sx={{
                            height: '14.5rem'
                        }} />

                    <Stack sx={{
                        width: 0.84,
                        flexDirection: 'row',
                        mt: 1.5,
                        justifyContent: 'center',
                    }}>
                        {/* 1 */}
                        <Stack sx={{
                            width: settingData.isMobile ? 0.19 : '120rem',
                            height: '295rem',
                            backgroundSize: '100% 100%',
                            backgroundImage: `url(${getImgPath('money_item_bg.png')})`,
                            alignItems: 'center',
                        }}>
                            <Typography sx={{
                                fontSize: '24rem',
                                color: 'white',
                                textAlign: 'center',
                                lineHeight: '45rem',
                                height: '45rem',
                                fontFamily: window.MyFont,
                                mb: rdd.isIPad13 ? 2.5 : 0,
                            }}>
                                100,000
                            </Typography>
                            <JinBiItem imgName={'ring.png'} num={1} w={0.7} mt={settingData.isMobile ? 1.3 : 2} />
                            <JinBiItem imgName={'diamond_1.png'} num={50} w={settingData.isMobile ? 0.65 : 0.85} mt={settingData.isMobile ? 1.3 : 2} />
                        </Stack>

                        {/* 2 */}
                        <Stack sx={{
                            ml: settingData.isMobile ? 0.5 : 1,
                            width: settingData.isMobile ? 0.19 : '120rem',
                            height: '295rem',
                            backgroundSize: '100% 100%',
                            backgroundImage: `url(${getImgPath('money_item_bg.png')})`,
                            alignItems: 'center',
                        }}>
                            <Typography sx={{
                                fontSize: '24rem',
                                color: 'white',
                                textAlign: 'center',
                                lineHeight: '45rem',
                                height: '45rem',
                                fontFamily: window.MyFont,
                                mb: rdd.isIPad13 ? 2.5 : 0,
                            }}>
                                300,000
                            </Typography>
                            <JinBiItem imgName={'ring_2.png'} num={1} w={settingData.isMobile ? 0.73 : 0.88} mt={settingData.isMobile ? 1.3 : 2} />
                            <JinBiItem imgName={'diamond_1.png'} num={50} w={settingData.isMobile ? 0.65 : 0.85} mt={settingData.isMobile ? 1.3 : 2} />
                        </Stack>


                        {/* 3 */}
                        <Stack sx={{
                            ml: settingData.isMobile ? 0.5 : 1,
                            width: settingData.isMobile ? 0.19 : '120rem',
                            height: '295rem',
                            backgroundSize: '100% 100%',
                            backgroundImage: `url(${getImgPath('money_item_bg.png')})`,
                            alignItems: 'center',
                        }}>
                            <Typography sx={{
                                fontSize: '24rem',
                                color: 'white',
                                textAlign: 'center',
                                lineHeight: '45rem',
                                height: '45rem',
                                fontFamily: window.MyFont,
                                mb: rdd.isIPad13 ? 2 : 0,
                            }}>
                                500,000
                            </Typography>
                            <JinBiItem imgName={'tag_word.png'} num={1} w={0.7} mt={settingData.isMobile ? 1 : 2} />
                            <JinBiItem imgName={'piao_jv.png'} num={1} w={0.5} mt={settingData.isMobile ? 1 : 1.5} />
                            <JinBiItem imgName={'ring_2.png'} num={2} w={0.55} mt={settingData.isMobile ? 1 : 1.5} />
                        </Stack>


                        {/* 4 */}
                        <Stack sx={{
                            ml: settingData.isMobile ? 0.5 : 1,
                            width: settingData.isMobile ? 0.19 : '120rem',
                            height: '295rem',
                            backgroundSize: '100% 100%',
                            backgroundImage: `url(${getImgPath('money_item_bg_gray.png')})`,
                            alignItems: 'center',
                        }}>
                            <Typography sx={{
                                fontSize: '24rem',
                                color: '#B4B4B4',
                                textAlign: 'center',
                                lineHeight: '45rem',
                                height: '45rem',
                                fontFamily: window.MyFont,
                                mb: rdd.isIPad13 ? 2.5 : 0,
                            }}>
                                800,000
                            </Typography>
                            <JinBiItem imgName={'diamond_1.png'} num={100} w={settingData.isMobile ? 0.4 : 0.55} mt={settingData.isMobile ? 0.8 : 1.3} />
                            <JinBiItem imgName={'piao_jv.png'} num={2} w={0.5} mt={settingData.isMobile ? 0.5 : 1} />
                            <JinBiItem imgName={'ring_2.png'} num={3} w={0.55} mt={settingData.isMobile ? 0.5 : 1} />
                        </Stack>

                        {/* 5 */}
                        <Stack sx={{
                            ml: settingData.isMobile ? 0.5 : 1,
                            width: settingData.isMobile ? 0.19 : '120rem',
                            height: '295rem',
                            backgroundSize: '100% 100%',
                            backgroundImage: `url(${getImgPath('money_item_bg_gray.png')})`,
                            alignItems: 'center',
                        }}>
                            <Typography sx={{
                                fontSize: '24rem',
                                color: '#B4B4B4',
                                textAlign: 'center',
                                lineHeight: '45rem',
                                height: '45rem',
                                fontFamily: window.MyFont,
                                mb: rdd.isIPad13 ? 2 : 0,
                            }}>
                                1,000,000
                            </Typography>
                            <JinBiItem imgName={'Icon_Item.png'} num={1000} w={settingData.isMobile ? 0.4 : 0.48} mt={settingData.isMobile ? 0.8 : 1.3} />
                            <JinBiItem imgName={'piao_jv.png'} num={3} w={0.5} mt={settingData.isMobile ? 0.5 : 1} />
                            <JinBiItem imgName={'ring_2.png'} num={4} w={0.55} mt={settingData.isMobile ? 0.5 : 1} />
                        </Stack>
                    </Stack>
                </Stack>

                {/* 底部 */}
                <Box sx={{
                    width: 1,
                    height: '300rem',
                    background: 'linear-gradient(#00000000 , #000000cc)',
                    position: 'absolute',
                    bottom: 0,
                }} />

                <Box
                    onClick={subscribeTap}
                    component={'img'}
                    src={getImgPath('swim5.png')}
                    sx={{
                        display: props.isDownload ? 'none' : 'block',
                        width: settingData.isMobile ? '100%' : '1000rem',
                        zIndex: 2,
                        ml: settingData.isMobile ? 1 : 0,
                        cursor: 'pointer',
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'scale(1.03)',
                        }
                    }} />

            </Stack>


        </Stack>
    );
}

function JinBiItem(props) {
    return (
        <Stack sx={{
            alignItems: 'center',
            mt: props.mt,
            position: 'relative',
        }}>
            <Box
                component={'img'}
                src={getImgPath(props.imgName)}
                sx={{
                    width: props.w,
                }} />

            <Stack sx={{
                width: '60rem',
                height: '20rem',
                borderRadius: '13rem',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundSize: '100% 100%',
                backgroundImage: `url(${getImgPath('num_bg.png')})`,
                justifyContent: 'center',
            }}>
                <Typography sx={{
                    fontSize: '15rem',
                    color: 'white',
                    textAlign: 'center',
                    lineHeight: '26rem',
                    height: '28rem',
                    fontFamily: window.MyFont,
                }}>
                    x {props.num}
                </Typography>
            </Stack>
        </Stack>
    );
}

