import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import * as JSTool from '../store/JSTool';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import NavView from '../component/NavView';
import { getImgPath } from '../store/JSTool';
import LeftDownload from '../component/LeftDownload';
import RightBottom from '../component/RightBottom';

import $ from 'jquery';
import Page0 from './Page0';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Footer from './Footer';
import CookieView from '../component/CookieView';
import LoadingPage from '../page/LoadingPage';

export default function HomePage() {

    let countTmp = 0;
    let isFinish = false;
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    React.useEffect(() => {
        $("#home_page_view").on('scroll', () => {
            let x = $('#home_page_view').scrollTop();
            processScroll(x);
        });
    }, []);

    function processScroll(x) {
        //显示隐藏
        let h = settingData.innerHeight < 500 ? 500 : settingData.innerHeight;
        if (x >= h - 80) {
            JSTool.setViewHidden(true);
        } else {
            JSTool.setViewHidden(false);
        }

         //导航颜色
        const height0 = $("#scroll_to0").height();
        const height1 = $("#page1").height();
        const height2 = $("#page2").height();
        const height3 = $("#page3").height();
        const height4 = $("#page4").height();

        // console.log('x:' + x + ' height0:' + height0 + ' height1:' + height1+ ' height2:' + height2+ ' height3:' + height3
        // + ' height4:' + height4
        // );

        if (x < height0 - 80) {
            if(settingObj.navIndex != 0){
                dispatch(settingActions.setNavIndex(0));
            }
            return;
        }
        if (x < height0 + height1 -80) {
            if(settingObj.navIndex != 1){
                dispatch(settingActions.setNavIndex(1));   
            }
            return;
        }
        if (x < height0 + height1 + height2 - 80) {
            if(settingObj.navIndex != 2){
                dispatch(settingActions.setNavIndex(2));   
            }
            return;
        }
        if (x < height0 + height1 + height2 + height3 - 50) {
            if(settingObj.navIndex != 3){
                dispatch(settingActions.setNavIndex(3));   
            }
            return;
        }
        if (x < height0 + height1 + height2 + height3 + height4) {
            if(settingObj.navIndex != 4){
                dispatch(settingActions.setNavIndex(4));   
            }
            return;
        }
    }

    return (
        <Stack
            sx={{
                width: 1,
                height: settingData.innerHeight,
                userSelect: 'none'
            }}>
            <NavView />
            <LeftDownload />
            <RightBottom />
            <CookieView/>

            <Stack
                id='home_page_view'
                sx={{
                    width: 1,
                    overflow: 'auto',
                }}>
                <Stack>
                    <Page0 anchorId={'scroll_to0'} />
                    <Page1 anchorId={'scroll_to1'} />
                    <Page2 anchorId={'scroll_to2'} />
                    <Page3 anchorId={'scroll_to3'} />
                    <Page4 anchorId={'scroll_to4'} />
                    <Footer />
                </Stack>
            </Stack>

            <LoadingPage />
        </Stack>
    );
}

