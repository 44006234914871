import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/JSTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import * as JSTool from '../store/JSTool';
import Footer from './Footer';


export default function PostView_CR() {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    React.useEffect(() => {

    }, []);

    return (
        <Stack>
            {JSTool.isCR() && <CRView />}
        </Stack>
    );
}

function CRView(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const hintTxt = "Celebrating the <strong>OVERLORD</strong> anime with a limited time <br/>promotion available to all Crunchyroll Members!";
    const txt_0 = "Log into <br/>Lord of Nazarick<br/>with your Crunchyroll account to enjoy<br/>exclusive rewards!<br/><br/> Crunchyroll <br/>Subscribers receive <br/>even bigger rewards<br/> plus access to<br/> exclusive shop <br/>discounts and<br/> battle passes!";
    const txt_1 = "Enter for a chance <br/>to win free movie <br/> tickets to OVERLORD:<br/> The Sacred Kingdom <br/>in theaters!";
    const txt_1_2 = "* Available in North<br/>America only. Terms and<br/>conditions apply.";

    const txt_2 = "10% off all<br/>OVERLORD merch <br/>and a free<br/> collectible art card<br/> with any purchase<br/> of OVERLORD <br/>manga!";
    const txt_2_2 = "* Available in North America <br/>only. While supplies latest.";

    const txt_3 = "Catch up on <br/>the OVERLORD <br/> anime series,<br/>streaming now on Crunchyroll!";


    return (
        <Stack sx={{
            bgcolor: '#ECE1C1',
            width: 1,
            minHeight: settingData.innerHeight,
            alignItems: 'center',
        }}>

            {/* CR */}
            <Stack sx={{
                bgcolor: 'black',
                width: 1,
                alignItems: 'center',
            }}>
                <Box component={'img'}
                    src={JSTool.getImgPath('cr_logo_new3.png')}
                    sx={{
                        width: '200rem',
                        my: '20rem',
                    }} />
            </Stack>

            {/* LOGO */}
            <Stack sx={{
                width: 1,
                position: 'relative',
                justifyContent: 'center',
            }}>
                <Box component={'img'}
                    src={JSTool.getImgPath('cr/title_bt.png')}
                    sx={{
                        width: 1,
                        maxHeight: '200rem',
                    }} />

                <Stack sx={{
                    position: 'absolute',
                    width: 1,
                    color: 'white',
                    alignItems: 'center',
                }}>
                    <Typography sx={{
                        fontSize: '20rem',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}>
                        Your #1 stop for the
                    </Typography>

                    <Box component={'img'}
                        src={JSTool.getImgPath('cr/logo.png')}
                        sx={{
                            width: '220rem',
                            my: 0.5,
                        }} />

                    <Typography sx={{
                        fontSize: '20rem',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}>
                        anime!
                    </Typography>
                </Stack>
            </Stack>

            {/* Title */}
            <Stack
                sx={{
                    width: settingData.isMobile ? 0.8 : '700rem',
                    height: '90rem',
                    backgroundSize: '100% 100%',
                    backgroundImage: `url(${getImgPath('cr/hit_bt.png')})`,
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'center',
                }} >
                <Box
                    component={'div'}
                    sx={{
                        mx: 1.5,
                        mb: 1.3,
                        textAlign: 'center',
                        color: 'black',
                        fontSize: settingData.isMobile ? '12px' : '18rem',
                    }}
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                           ${hintTxt}
                            `
                    }} />

            </Stack>

            {/* 第一个 */}
            <Stack
                sx={{
                    width: settingData.isMobile ? 0.95 : '800rem',
                    height: '450rem',
                    backgroundSize: '100% 100%',
                    backgroundImage: `url(${getImgPath('cr/cr_post_0.png')})`,
                    mt: '20rem',
                    position: 'relative',
                }} >
                <Stack sx={{
                    width: 0.275,
                    height: '1',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pt: settingData.isMobile ? 3 : 4,
                }}>

                    <Box
                        component={'div'}
                        sx={{
                            px: 1,
                            textAlign: 'center',
                            color: 'black',
                            fontSize: settingData.isMobile ? '17rem' : '16rem',
                            pb: settingData.isMobile ? 1 : 2,
                        }}
                        dangerouslySetInnerHTML={{
                            __html:
                                `
                           ${txt_0}
                            `
                        }} />

                    <Typography sx={{
                        px: 3,
                        py: 1,
                        color: 'white',
                        cursor: 'pointer',
                        bgcolor: 'black',
                        fontSize: '13rem',
                    }}>
                        PLAY NOW!
                    </Typography>

                </Stack>
            </Stack>

            {/* 第二个 */}
            <Stack
                sx={{
                    width: settingData.isMobile ? 0.95 : '800rem',
                    height: '330rem',
                    backgroundSize: '100% 100%',
                    backgroundImage: `url(${getImgPath('cr/cr_post_1.png')})`,
                    mt: '20rem',
                }} >

                <Stack sx={{
                    width: 0.275,
                    height: '1',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pt: settingData.isMobile ? 5 : 8,
                }}>
                    <Box
                        component={'div'}
                        sx={{
                            px: 1,
                            textAlign: 'center',
                            color: 'black',
                            fontSize: '16rem',
                        }}
                        dangerouslySetInnerHTML={{
                            __html:
                                `
                           ${txt_1}
                            `
                        }} />

                    <Typography sx={{
                        px: 3,
                        py: 1,
                        my: settingData.isMobile ? 0.5 : 1,
                        color: 'white',
                        cursor: 'pointer',
                        bgcolor: 'black',
                        fontSize: '13rem',
                    }}>
                        ENTER HERE!
                    </Typography>

                    <Box
                        component={'div'}
                        sx={{
                            px: 1,
                            textAlign: 'center',
                            color: 'black',
                            fontSize: '16rem',
                            pb: 2,
                        }}
                        dangerouslySetInnerHTML={{
                            __html:
                                `
                           ${txt_1_2}
                            `
                        }} />

                </Stack>

            </Stack>

            {/* 第三个 */}
            <Stack
                sx={{
                    width: settingData.isMobile ? 0.95 : '800rem',
                    height: '330rem',
                    backgroundSize: '100% 100%',
                    backgroundImage: `url(${getImgPath('cr/cr_post_2.png')})`,
                    mt: '20rem',
                }} >

                <Stack sx={{
                    width: 0.275,
                    height: '1',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pt: settingData.isMobile ? 5 : 8,
                }}>

                    <Box
                        component={'div'}
                        sx={{
                            px: 1,
                            textAlign: 'center',
                            color: 'black',
                            fontSize: settingData.isMobile ? '14rem' : '15rem',
                        }}
                        dangerouslySetInnerHTML={{
                            __html:
                                `
                           ${txt_2}
                            `
                        }} />

                    <Typography sx={{
                        px: 3,
                        py: 1,
                        my: settingData.isMobile ? 0.5 : 1,
                        color: 'white',
                        cursor: 'pointer',
                        bgcolor: 'black',
                        fontSize: '13rem',
                    }}>
                        SHOP NOW!
                    </Typography>

                    <Box
                        component={'div'}
                        sx={{
                            px: 1,
                            textAlign: 'center',
                            color: 'black',
                            fontSize: settingData.isMobile ? '14rem' : '15rem',
                            pb: 2,
                        }}
                        dangerouslySetInnerHTML={{
                            __html:
                                `
                           ${txt_2_2}
                            `
                        }} />

                </Stack>

            </Stack>

            {/* 第四个 */}
            <Stack
                sx={{
                    width: settingData.isMobile ? 0.95 : '800rem',
                    height: '280rem',
                    backgroundSize: '100% 100%',
                    backgroundImage: `url(${getImgPath('cr/cr_post_3.png')})`,
                    mt: '20rem',
                    mb: '20rem',
                }} >

                <Stack sx={{
                    width: 0.275,
                    height: '1',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pt: settingData.isMobile ? 3 : 6,
                }}>

                    <Box
                        component={'div'}
                        sx={{
                            px: 1,
                            textAlign: 'center',
                            color: 'black',
                            fontSize: settingData.isMobile ? '17rem' : '16rem',
                        }}
                        dangerouslySetInnerHTML={{
                            __html:
                                `
                           ${txt_3}
                            `
                        }} />

                    <Typography sx={{
                        px: 3,
                        py: 1,
                        mt: settingData.isMobile ? 1 : 2,
                        color: 'white',
                        cursor: 'pointer',
                        bgcolor: 'black',
                        fontSize: '13rem',
                    }}>
                        WATCH NOW!
                    </Typography>
                </Stack>
            </Stack>
            <Footer />
        </Stack>
    );
}